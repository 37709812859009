import {getCodeByName, removeUndefinedOrNull} from "../Utilities/utils";
import * as _ from 'underscore'

const axios = require('axios');

export async function sendDataToShippit(data) {
    const payload = buildShippitPayload(data);
    return sendToShippitAPI(payload);
}

export function buildShippitPayload(row) {
    const address = (row.structuredData.shippingAddress?.addressLine1 ?? "") + ", " + ((row.structuredData.shippingAddress?.addressLine2 ?? ""))

    const metaAddress = row?.meta?.overridden_shipping_address
    const sendAddress = _.isEmpty(metaAddress) ? address : metaAddress

    const parcels = row.structuredData.lineItems.map((item) => {
        return {
            "qty": item.quantity,
            "weight": 1
        }
    });

    const shippingName = row?.structuredData?.shippingAddress?.addressName

    //split the shipping name to first name and last name
    const nameParts = shippingName?.split(' ') || [];
    const firstName = nameParts[0] || '';
    // Join all remaining parts for the last name to handle multi-word last names
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';

    const out = {
        "order": {
            "retailer_invoice": row.prontoOrderId,
            "receiver_contact_number": row.userPhoneNumber,
            "delivery_instructions": row.structuredData.deliveryInstructions,
            "courier_type": "standard",
            "delivery_address": sendAddress,
            "delivery_postcode": row.structuredData.shippingAddress?.postcode,
            "delivery_state": getCodeByName(row.structuredData.shippingAddress?.region),
            "delivery_suburb": row.structuredData.shippingAddress?.locality,
            "authority_to_leave": "",
            "price": row.totalValue,
            "parcel_attributes": parcels,
            "user_attributes": {
                "email": row.structuredData.email,
                "first_name": firstName,
                "last_name": lastName
            }
        }
    }

    console.log("SHIPPING NAME CHANGE",out)

    return removeUndefinedOrNull(out);
}

const { REACT_APP_API_URL, REACT_APP_STRAPI_TOKEN_KEY } = process.env;

const getAxiosConfig = () => {
    const token = localStorage.getItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
    if (!token) {
        return {};
    }
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};


export async function sendToShippitAPI(order) {
    return await axios.post(
        `${REACT_APP_API_URL}/services/shipping/sendordertoshippit`,
        order,
        getAxiosConfig()
    );
}

// async function sendToShippitAPI(payload) {
//     const url = 'https://app.shippit.com/api/3/orders';
//     const apiKey = process.env.SHIPPIT_API_KEY; // API key stored in environment variable
//
//     console.log(process.env)
//     console.log(apiKey)
//
//     return axios.post(url, payload, {
//         headers: {'Authorization': 'xrJvbAfgLi5bLwAAPO8N_w'},
//         contentType: 'application/json'
//     });
// }

// Sample usage
// Assuming you have the data array ready (similar to what you had in Google Sheets)
// const data = [...];
// sendDataToShippit(data);
